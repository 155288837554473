import React, { useState, useContext, useRef, useReducer, useEffect} from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import AthletesTable from 'components/cronoapp/AthletesTable';
import { AppContext } from '../store/createContext';
import {
  Grommet,
  Box,
  Tab,
  Tabs,
} from 'grommet';
import TableToolBar from 'components/cronoapp/TableToolBar';
import jstz from 'jstz';
import { sortByPosition, filterByAttributes, processAthletes } from '../utils/AthletesHandlers';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useAsyncTask } from 'react-hooks-async';
import  ErrorBoundary  from 'components/utils/ErrorBoundary'

const fetchRaceTable = async ({ signal }, raceid) => {
  const response = await fetch(
    `${process.env.GATSBY_DRUPAL_API_URL}/api/match/${raceid}/full`,
    { signal }
  );
  const data = await response.json();
  return data.data;
};

const TableHero = (raceid, load) => {
  const { start, started, error, result, pending } = useAsyncTask(fetchRaceTable);

  const filters = result
    ? {
        categories: _.uniq(
          result.athletes.map(obj => {
            return obj['category'];
          })
        ).concat(''),
        genders: _.uniq(
          result.athletes.map(obj => {
            return obj['gender'];
          })
        ).concat(''),
      }
    : {};

  const timezone = jstz.determine();
  const componentIsMounted = useRef(true);
  const columns = result ? result.race_columns : [];
  const startDate = result ? parseInt(result.start_date) : null;
  const [filterInput, setFilterInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      gender: '',
      category: '',
      start_date: '',
      start_mode: 'fixed',
    }
  );

  const handleFilterAthletes = ({ value, filter }) => {
    //console.log('Set Filter',   value, filter)

    // const { name, value } = event.target;

    setFilterInput({ [filter]: value });
  };

  const handleRefreshClick = event => {
    console.log("refresh",raceid)
    start(raceid.raceid);
  };

  const filterAthletes = list => {
    //console.log('Filters', filterInput)
    return list.filter(item => {
      return (
        item.category
          .toLowerCase()
          .includes(filterInput.category.toLowerCase()) &&
        item.gender.toLowerCase().includes(filterInput.gender.toLowerCase())
      );
    });
  };

  //Create Athletes array with the filters applied and Ordered by Time(best time on last checkpoint record added.)
  const athletes =
    result && sortByPosition(filterAthletes(processAthletes(result.athletes,columns,startDate, filterInput)), columns, filterInput);
    ///result && console.log("LISTA LIMPIA", result.athletes)
  const id = load ? raceid : raceid.raceid

  return (
    <Box margin="small" pad="large" align="center" border="all">

      {!result &&
            <button type="button" onClick={() => start(raceid.raceid)}>
              Ver Tabla
            </button>
      }
      {error && 'Error:' + error.message}
      {started && 'Fetching...'}
      {result && (
        <div>
          <TableToolBar
            handleRefreshClick={handleRefreshClick}
            handleFilterAthletes={handleFilterAthletes}
            filterInput={filterInput}
            filters={filters}
            active={!started}
           />
          {/* <ErrorBoundary > */}
          <AthletesTable
            key={result.id}
            athletes={Object(athletes)}
            columns={Object(columns)}
            start_date={startDate}
            start_mode={filterInput.start_mode}
          />
          {/* </ErrorBoundary> */}
        </div>
      )}
    </Box>
  );
};

const TableLive = (props) => {
  const [activeTable, setActiveTable] = useState(0);
  const { currentEvent } = useContext(AppContext);

  const onActive = nextIndex => setActiveTable(nextIndex);
   const races = currentEvent.races;
  const race_id = props.id ? props.id : null
  return (
    <Layout>
      <Box>
           {race_id &&  <TableHero raceid={race_id} load={true} />}


        <Tabs activeIndex={activeTable} onActive={onActive}>
          {(races && race_id == null)  &&
            races.map(race => (
              <Tab key={race.nid} title={race.name}>
                <TableHero raceid={race.nid} load={false} />
              </Tab>
            ))}
        </Tabs>


      </Box>

      <div style={{ height: '50vh' }} />
    </Layout>
  );
};

export default TableLive;
